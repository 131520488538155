<template>
  <div id="mian">
    <el-dialog
      title="新增店铺"
      :visible.sync="centerDialogVisible"
      width="490px"
    >
      <ul>
        <li style="width: 200px; margin: 0 auto" class="my-el-dialog">
          <div class="overAccount_ul_title my-el-dialog-item">
            请选择录入方式
          </div>
          <el-radio class="my-el-dialog-item" v-model="radio" label="1"
            >手工录入</el-radio
          >
          <el-radio class="my-el-dialog-item" v-model="radio" label="2"
            >API</el-radio
          >
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button class="addBt" @click="addclickHander">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>店铺名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="店铺名称/店铺编号"
          ></el-input>
        </div>
        <div class="item">
          <i>所属商户</i>
          <el-select
            v-model="query.mchId"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <i>路由状态</i>
          <el-select
            v-model="query.pollingStatus"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="开" :value="true"></el-option>
            <el-option label="关" :value="false"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item">
          <i>店铺来源</i>
          <el-select
            v-model="query.storeSource"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="API录入" value="API"></el-option>
            <el-option label="手工录入" value="MANUAL"></el-option>
          </el-select>
        </div>
        <div class="item">
          <i>商户显示状态</i>
          <el-select v-model="query.showInMch" >
            <el-option label="全部" value=""></el-option>
            <el-option label="显示" :value="true"></el-option>
            <el-option label="隐藏" :value="false"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item itemTime">
          <i>创建时间</i>
          <el-date-picker
            v-model="query.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px">-</span>
          <el-date-picker
            v-model="query.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
        </div>
        <div class="itembtn">
          <el-button v-auth="'ACCOUNT:STORE:STORES/JJPZBTN'" type="primary" class="outputBt" @click="entryConfig()">进件配置</el-button>
          <el-button type="primary" class="searchBt" @click="getLists"
            >查询</el-button
          >
            <el-button
            v-auth="'ACCOUNT:STORE:STORES/ADD'"
            class="outputBt"
            @click="centerDialogVisible = true"
            >新增</el-button
          >
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>创建时间</p>
          </td>
          <td>
            <p>店铺名称</p>
          </td>
          <td>
            <p>店铺编号</p>
          </td>
          <td>
            <p>所属商户</p>
          </td>
          <td>
            <p>路由状态</p>
          </td>
          <td>
            <p>商户显示状态</p>
          </td>
          <td>
            <p>店铺来源</p>
          </td>
          <td style="width: 60px">
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="v in tabData" :key="v.storeId">
          <td>
            <p>{{ v.createTime }}</p>
          </td>
          <td>
            <p>{{ v.storeName }}</p>
          </td>
          <td>
            <p>{{ v.storeId }}</p>
          </td>
          <td>
            <p>{{ v.mchName }}</p>
          </td>
          <td>
            <p v-show="OFFON_PollingStatus != 'ACCOUNT:STORE:STORES/ROUTINGS'"> {{ v.pollingStatus==true ? "已开启" : "已关闭" }}</p>
            <p v-auth="'ACCOUNT:STORE:STORES/ROUTINGS'">
              <el-switch
                v-model="v.pollingStatus"
                @change="setPollingStatus(v)"
                active-color="#13ce66"
              >
              </el-switch>
            </p>
          </td>
          <td>
            <p v-show="OFFON_ShowInMch != 'ACCOUNT:STORE:STORES/DISPALY'"> {{ v.showInMch==true ? "已开启" : "已关闭" }}</p>
            <p v-auth="'ACCOUNT:STORE:STORES/DISPALY'">
              <el-switch
                v-model="v.showInMch"
                @change="setInMchStatus(v)"
                active-color="#13ce66"
              >
              </el-switch>
            </p>
          </td>
          <td>
            <p>{{ storeSource[v.storeSource] }}</p>
          </td>
          <td style="width: 60px">
            <p>
              <a
                v-auth="'ACCOUNT:STORE:STORES/DETAIL'"
                href="javascript:;"
                @click="detailTab(v)"
                class="lianjie"
                >详情</a
              >
            </p>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    >
    </el-pagination>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { merchantsDrop } from "@/api/common.js";
import {
  storesList,
  setPollingStatus,
  setShowStatus,
} from "@/api/user/shopList.js";
export default {
  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
        mchId: "",
        startTime: "",
        endTime: "",
        pollingStatus: "",
        storeSource: "",
        keyword: "",
        showInMch: "",
      },
      totalNum: 0,
      tabData: [],
      mchDrop: [], //商户下拉
      storeSource: {
        API: "API录入",
        MANUAL: "手工录入",
      },
      centerDialogVisible: false,
      radio: "1",

      OFFON_PollingStatus : 'ACCOUNT:STORE:STORES/ROUTINGS',
      OFFON_ShowInMch : 'ACCOUNT:STORE:STORES/DISPALY',
    };
  },
  computed: {
    ...mapState({
      storesQueryDetail: "user_storesQuery",
    }),
  },
  created() {
    if (this.storesQueryDetail) {
      this.query = this.storesQueryDetail.query;
    }
    this.getLists(false);
    this.getDrop();
    this.$enter(this.$route.path, this.getLists);
    this.listJurisdiction();
  },
  methods: {
    ...mapMutations({
      setStoresQuery: "user_setStoresQuery",
      setStoresManual: "user_setStoresManual",
      setStoresApi: "user_setStoresApi",
    }),
    // 获取下拉菜单
    getDrop() {
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    //获取列表
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.startTime = this.query.startTime ? this.query.startTime : "";
      this.query.endTime = this.query.endTime ? this.query.endTime : "";
      this.query.keyword = this.query.keyword.trim();

      storesList(this.query).then((res) => {
        if (res) {
          this.tabData = res.resultData.stores;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    //修改轮训状态
    setPollingStatus(value) {
      let data = {
        storeId: value.storeId,
        pollingStatus: value.pollingStatus,
      };
      setPollingStatus(data).then((res) => {
        if (!res) {
          value.pollingStatus = value.pollingStatus ? false : true;
        }
      });
    },
    //修改商户显示状态
    setInMchStatus(value) {
      let data = {
        storeId: value.storeId,
        showInMch: value.showInMch,
      };
      setShowStatus(data).then((res) => {
        if (!res) {
          value.showInMch = value.showInMch ? false : true;
        }
      });
    },
    //新增按钮
    addclickHander() {
      if (this.radio == 1) {
        this.setStoresManual(null);
        this.$router.push({
          name: "add_shoplist_user",
        });
      } else {
        this.setStoresApi(null);
        this.$router.push({
          name: "add_shoplist",
        });
      }
    },
    //详情
    detailTab(value) {
      // console.log("InfoTypeInfoType",value)
      // return
      this.$router.push({
        name: "shoplist_xq",
        query: {
          id: value.storeId,
          pageType: "edit",
          InfoType: value.storeSource,
        },
      });
    },
    //
    entryConfig(){
      this.$router.push({
        name: "entryConfig",
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
    //判断列表按钮是否有权限
    listJurisdiction(){
        const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
        if(_resources=='ALL'){
            this.OFFON_PollingStatus = 'ACCOUNT:STORE:STORES/ROUTINGS';
            this.OFFON_ShowInMch = 'ACCOUNT:STORE:STORES/DISPALY';
        }else{
          if(_resources.split(';').indexOf('ACCOUNT:STORE:STORES/ROUTINGS') !== -1 ){
            this.OFFON_PollingStatus = 'ACCOUNT:STORE:STORES/ROUTINGS';
          }else{
            this.OFFON_PollingStatus = "";
          }
          if(_resources.split(';').indexOf('ACCOUNT:STORE:STORES/DISPALY') !== -1 ){
            this.OFFON_ShowInMch = 'ACCOUNT:STORE:STORES/DISPALY';
          }else{
            this.OFFON_ShowInMch = "";
          }
        }

    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setStoresQuery(data);
  },
};
</script>
<style scoped>
.con_from .lineP .item{
  width: 29%;
  margin-left: 6.5%;
}
.con_from .lineP .item:first-child{
  margin-left: 0%;
}
.con_from .lineP .item i {
  width: 92px;
}
.con_from .lineP .itemTime {
  width: 50.55%;
}
.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 92px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 92px - 24.67px - 10px) / 2);
}

.addDetailBt {
  float: right;
  width: 90px;
  height: 36px;
  margin-left: 20px;
  border-color: #898d92;
  padding: 0;
}

.addDetailBt:hover {
  background-color: #fff;
  color: #48b8b6;
}


.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.my-el-dialog {
  display: flex;
  flex-direction: column;
}

.my-el-dialog-item {
  height: 36px;
  line-height: 36px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}
</style>
